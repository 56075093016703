.form {
  width: 40%;
  border: 2px solid #6e6a6a;
  padding: 1rem;
}
.layout {
  margin: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.dropdown {
  width: 20rem;
}
@media only screen and (max-width: 600px) {
  .form {
    border: none;
    padding: 0;
    width: 90%;
  }
  .layout {
    margin: 0;
    display: flex;
    flex-direction: column;
    border: none;
  }
}
